enum ActionTypesSite {
    DEFAULT_TYPE = 'DEFAULT_TYPE',
    SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE',
    SET_ROLE = 'SET_ROLE',
    SAVE_OKTA_DATA = 'SAVE_OKTA_DATA',
    SET_AGREEMENT_STATUS = 'SET_AGREEMENT_STATUS',
    SET_TOKEN = 'SET_TOKEN',
}

const ActionTypes = { ...ActionTypesSite };

export default ActionTypes;
