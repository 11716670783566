import { IOktaData } from 'src/helpers/parseOktaData';

import { IBasal, IBolus } from './insulinUsageStatistics.models';
import {
    IGlucoseDisplayRange,
    IGlucoseRanges,
    IGlucoseChartPointInput,
    IPartsOfDay,
    ISummarySettingsDevice,
} from './glucoseChart.model';
import {
    ICarbsPoint,
    IBolusPoint,
    IModesData,
    IDailyChartOptions,
    IDailyGlucose,
    IUTCOffset,
    IPodChange,
    IBasalSubmode,
} from './dailyChart.models';
import { IReportSettings } from './settings.model';

export type AppStateType = {
    activePage: string;
    role: string;
    oktaData: IOktaData | null;
    agreementStatus: AgreementStatus;
    token: string;
};

export enum UserRole {
    Admin = 'Admin',
    Doctor = 'Doctor',
}

export enum AgreementStatus {
    EULA = 'eula',
    CONFIRMED = 'confirmed',
}

export enum Devices {
    Omnipod5 = 'Omnipod5',
}

export interface IPatientData {
    birthdate: string | null;
    firstName: string | null;
    lastName: string | null;
    age: number | null;
    days: number | null;
    startDate: string | null;
    endDate: string | null;
    createdDate: string | null;
    glucoseUnits: string | null;
    modeHypoProtectTargetGlucose: number | null;
    dataCharacteristics: IDataCharacteristics;
    glucose: IGlucoseChartData;
    daily: IDayData[];
    timeInRange: ITimeRangeData[] | null;
    glucoseStatistics: ISummeryMetricsData[];
    deviceUse: ISummeryDeviceUseData;
    glucoseRanges: IGlucoseRanges;
    targetGlucoseSettings: IGlucoseSettings;
    insulinCorrectionFactorSettings: IGlucoseSettings;
    insulinToCarbRatioSettings: IGlucoseSettings;
    correctAboveSettings: IGlucoseSettings;
    summarySettingsDevice: ISummarySettingsDevice;
    partsOfDay: IPartsOfDay;
    insulinStatistics: IInsulinUsageData | null;
    settings: IReportSettings | null;
}

export interface IGlucoseSettings {
    data: IChartDataWithDuration[];
    units: string;
}

export interface IChartDataWithDuration {
    x: number;
    duration: number;
    value: string;
}

export interface ITimeRangeData {
    id: string;
    title: string;
    actualPercent: number;
    sumPercents: number;
    actualHours: number;
    actualMinutes: number;
    targetPercent: string | number;
    interval: string;
    units: string;
    position: string;
    isShowInTarget: boolean;
}

export interface IInsulinUsageData {
    total: number;
    basal: IBasal;
    bolus: IBolus;
}

export interface ISummeryMetricsData {
    title: string;
    value: number | null;
    units: string;
    goal?: number | null;
    badge?: string;
    wrapValue?: string;
    className?: string;
    type?: string;
}

export interface ISummeryDeviceUseData {
    device: string;
    data: ISummeryMetricsData[];
}

export interface IDaySectionTotal {
    total: number;
}
export interface IDaySectionData<IData> {
    data: IData;
}
export interface IDayDataSection<IData> extends IDaySectionTotal, IDaySectionData<IData> {}

export interface IDayData {
    options: IDailyChartOptions;
    carbs: IDayDataSection<ICarbsPoint[][]>;
    bolus: IDayDataSection<IBolusPoint[][]>;
    basal: IDaySectionTotal;
    basalMode: IDaySectionData<IModesData[]>;
    basalSubmode: IDaySectionData<IBasalSubmode[]>;
    podChange: IDaySectionData<IPodChange[]>;
    date: string;
    glucose: IDailyGlucose;
    utcOffset?: IUTCOffset;
    hasData: boolean;
}

export interface IGlucoseChartData {
    statistics?: IGlucoseChartPointInput[];
    ranges: IGlucoseRanges;
    displayRange: IGlucoseDisplayRange;
    duration: number;
}

export interface IId {
    id: string;
}

export interface IDataCharacteristics {
    noSufficientForSummary: boolean;
    sufficiencyForSummaryCheckPeriodDays: number;
    summaryIsEmpty: boolean;
    noRecentDays: number;
    noRecentData: boolean;
}

export interface IUser {
    approvalStatus: null | string;
    email: string;
    id: string;
    isLatestEulaVersionAccepted: boolean;
    name: {
        first: string;
        last: string;
    };
    role: string;
}
