import {
    getPendingPatientsDataConfig,
    approvePendingPatients,
    denyPendingPatients,
} from 'src/web-services/Admin.service';

import { createPendingPatientsStructure } from '../createPendingPatientsStructure';

export async function getPendingPatientsData() {
    const res = await getPendingPatientsDataConfig();
    const { items, totalCount } = res?.data || { items: null };

    return {
        items: items?.length ? createPendingPatientsStructure(items) : [],
        totalCount: totalCount || 0,
    };
}

export async function approvePatients(patientsId: string[]) {
    await approvePendingPatients(
        patientsId.map((patientId) => ({
            id: patientId,
        }))
    );
}

export async function denyPatients(patientsId: string[]) {
    await denyPendingPatients(
        patientsId.map((patientId) => ({
            id: patientId,
        }))
    );
}
