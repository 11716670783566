import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// import Help from 'src/components/help/Help';
// import PendingProviders from 'src/components/pendingProviders/PendingProviders';
// import PatientDirectory from 'src/components/patientDirectory/PatientDirectory';
import Sidebar from 'src/components/sidebar/Sidebar';
import { RootStateType } from 'src/stores/store';
// import About from 'src/components/about/About';
import Upload from 'src/components/upload/Upload';
import Loading from 'src/components/loading/Loading';
import Agreements from 'src/components/agreements/Agreements';
import ErrorHandler from 'src/components/errorHandler/ErrorHandler';
import { UserRole, AgreementStatus, IUser } from 'src/models/app.models';
import { adminsSidebar, doctorsSidebar, pendingSidebar, webUploaderSidebar } from 'src/models/consts/sidebarItems';
// import Providers from 'src/components/providers/Providers';
import {
    ErrorPages,
    FreeAccessPage,
    // SidebarAdmin,
    // SidebarPage
} from 'src/models/table.models';
// import PendingPatients from 'src/components/pendingPatients/PendingPatients';
import { setActivePage, setAgreementStatus, setRole } from 'src/stores/appStore/appActions';
// import PatientsApproved from 'src/components/patientsApproved/PatientsApproved';
import { getCurrentClinicalWorker } from 'src/helpers/services/authentication';
import { isFreeAccessPage, getPostAuthenticationRedirectUrl } from 'src/helpers/routing';
// import Footer from 'src/components/footer/Footer';
import { handleError } from 'src/helpers/handleError';
import { getRuntimeConfig } from 'src/helpers/envReader';
import { sendAmplitudeEvent } from 'src/helpers/amplitude';
import { amplitudeEventsConstants } from 'src/models/consts/amplitude';
import { isBrowserSupported } from 'src/helpers/isBrowserSupported';

const Home = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(true);

    const activeRole = useSelector((state: RootStateType) => state.appState.role);
    const agreementStatus = useSelector((state: RootStateType) => state.appState.agreementStatus);
    const oktaData = useSelector((state: RootStateType) => state.appState.oktaData);
    const token = useSelector((state: RootStateType) => state.appState.token);

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    const isProduction = () => {
        return getRuntimeConfig('REACT_APP_IS_PRODUCTION') === 'true';
    };

    const showPhysicianUser = (user: IUser) => {
        dispatch(setRole(UserRole.Doctor));
        sendAmplitudeEvent(amplitudeEventsConstants.SET_USER_TYPE, { userType: UserRole.Doctor });
        history.push(getPostAuthenticationRedirectUrl(history.location.pathname, UserRole.Doctor));

        if (!isFreeAccessPage(history.location.pathname)) {
            if (user?.approvalStatus === 'denied') dispatch(setActivePage(ErrorPages.physicianDenied));
            if (user?.approvalStatus === 'pending') dispatch(setActivePage(ErrorPages.physicianPending));
        }
    };

    const showAdminUser = () => {
        dispatch(setRole(UserRole.Admin));
        sendAmplitudeEvent(amplitudeEventsConstants.SET_USER_TYPE, { userType: UserRole.Admin });
        history.push(getPostAuthenticationRedirectUrl(history.location.pathname, UserRole.Admin));
    };

    const checkUserRole = (user: IUser) => {
        if (!user?.isLatestEulaVersionAccepted) {
            dispatch(setAgreementStatus(AgreementStatus.EULA));
            return;
        }
        switch (user?.role) {
            case 'physician':
                showPhysicianUser(user);
                break;
            case 'clinic_admin':
                showAdminUser();
                break;

            default:
                if (!isFreeAccessPage(history.location.pathname)) dispatch(setActivePage(ErrorPages.noRole));
                break;
        }
    };

    useEffect(() => {
        if (oktaData && !activeRole && agreementStatus === AgreementStatus.CONFIRMED) {
            dispatch(setActivePage(history.location.pathname));
            (async () => {
                try {
                    setLoading(true);
                    const user = await getCurrentClinicalWorker();
                    setLoading(false);
                    if (!user?.id) {
                        throw Error();
                    } else {
                        checkUserRole(user);
                    }
                } catch (err) {
                    setLoading(false);
                    if (!isFreeAccessPage(history.location.pathname)) handleError(dispatch, err);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, activeRole, agreementStatus, oktaData, history.location.pathname]);

    const sidebarContent = useMemo(() => {
        return webUploaderSidebar;
        if (activeRole === UserRole.Doctor) return doctorsSidebar;
        if (activeRole === UserRole.Admin) return adminsSidebar;
        return pendingSidebar;
    }, [activeRole]);

    const supportedBrowser = useMemo(() => isBrowserSupported(), []);

    return (
        <div className="home">
            {!supportedBrowser && (
                <div className="home__banner">
                    <div>{t('browserNotSupported')}</div>
                </div>
            )}
            <div className="home__main-content">
                <Sidebar items={sidebarContent} isProduction={isProduction()} />
                <Route exact path={FreeAccessPage.upload} component={Upload} />
                {oktaData && (
                    <ErrorHandler>
                        <Agreements>
                            {loading ? (
                                <Loading />
                            ) : (
                                <>
                                    {/* <Route exact path={SidebarAdmin.patients} component={PatientsApproved} />
                                    <Route exact path={SidebarAdmin.pendingPatients} component={PendingPatients} />
                                    <Route exact path={SidebarAdmin.providers} component={Providers} />
                                    <Route exact path={SidebarAdmin.pendingProviders} component={PendingProviders} />
                                    <Route exact path={SidebarAdmin.help} component={Help} />
                                    <Route exact path={SidebarAdmin.about} component={About} />
                                    <Route exact path={SidebarPage.patients} component={PatientDirectory} />
                                    <Route exact path={FreeAccessPage.help} component={Help} />
                                    <Route exact path={FreeAccessPage.about} component={About} /> */}
                                    <Route exact path={FreeAccessPage.upload} component={Upload} />
                                </>
                            )}
                        </Agreements>
                    </ErrorHandler>
                )}
            </div>
            {/* <div className="home__footer">
                <Footer />
            </div> */}
        </div>
    );
};

export default Home;
