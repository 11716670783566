export interface ITableColumns {
    name: string;
    sort?: boolean;
    align?: string;
    size?: string;
}

export interface IPatientsTableRows {
    id: string;
    firstName: string;
    lastName: string;
    dob: string;
    days: number | string;
    hasDeviceSettings: boolean;
    lastDate: string;
}

export interface IPendingPatientsTableRows {
    id: string;
    firstName: string;
    lastName: string;
    dob: string;
    requestedDate: string;
    mrn: string;
}

export interface IPendingProvidersTableRows {
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
    email: string;
}

export interface IProvidersTableRows {
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
    email: string;
}

export enum SidebarAdmin {
    patients = '/admin/patients/approved',
    providers = '/admin/providers/approved',
    pendingPatients = '/admin/patients/pending',
    pendingProviders = '/admin/providers/pending',
    help = '/admin/help',
    about = '/admin/about',
}

export enum SidebarPage {
    patients = '/patients',
}

export enum FreeAccessPage {
    help = '/help',
    about = '/about',
    home = '/home',
    training = '/training',
    prescribe = '/prescribe',
    upload = '/upload',
}

export enum ErrorPages {
    authenticationFailure = 'authenticationFailure',
    noRole = 'noRole',
    PhysicianNotApproved = 'physicianNotApproved',
    physicianDenied = 'physicianDenied',
    physicianPending = 'physicianPending',
    clinicalWorkerNotFound = 'clinicalWorkerNotFound',
    physiciansClinicAssociationStatusChangeFailed = 'PhysiciansClinicAssociationStatusChangeFailed',
    physiciansClinicAssociationStatusChangePartiallyFailed = 'PhysiciansClinicAssociationStatusChangePartiallyFailed',
    patientsClinicAssociationStatusChangeFailed = 'PatientsClinicAssociationStatusChangeFailed',
    patientsClinicAssociationStatusChangePartiallyFailed = 'PatientsClinicAssociationStatusChangePartiallyFailed',
    recall = 'recall',
}
