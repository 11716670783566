import axios from 'axios';

import { createApiPath } from 'src/helpers/createPath';
import { IId } from 'src/models/app.models';

export async function getApprovedPatientsDataConfig() {
    const res = await axios.get(createApiPath('patients'));
    return res?.data;
}

export async function getPendingPatientsDataConfig() {
    const res = await axios.get(createApiPath('admin/patients/pending'));
    return res?.data;
}

export async function approvePendingPatients(data: IId[]) {
    await axios.post(createApiPath('admin/patients/approve'), data);
}

export async function denyPendingPatients(patientsId: { id: string }[]) {
    return (await axios.post(createApiPath('admin/patients/deny'), patientsId))?.data;
}

export async function getProvidersUsersDataConfig() {
    const res = await axios.get(createApiPath('admin/providers/approved'));
    return res?.data;
}

export async function getPendingProvidersDataConfig() {
    const res = await axios.get(createApiPath('admin/providers/pending'));
    return res?.data;
}

export async function approvePendingProviders(patientsId: { id: string }[]) {
    await axios.post(createApiPath('admin/providers/approve'), patientsId);
}

export async function denyPendingProviders(patientsId: { id: string }[]) {
    return (await axios.post(createApiPath('admin/providers/deny'), patientsId))?.data;
}
