import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'react-datetime-picker';
import { detect } from 'detect-browser';
import axios from 'axios';

import { createApiPath } from 'src/helpers/createPath';
import { IUpload } from 'src/models/upload.models';

// components
import Modal from '../modal/Modal';
import Button from '../button/Button';
import Loading from '../loading/Loading';

const Upload = () => {
    const { t } = useTranslation();

    const fileRef = useRef() as any;

    const [uploadForm, setUploadForm] = useState({
        firstName: '',
        lastName: '',
        clinicName: '',
        comments: '',
    } as IUpload);
    const [fileId, setFileId] = useState();
    const [file, setFile] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const [dateTime, setDateTime] = useState(new Date());
    const [modalText, setModalText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showDateTime, setShowDateTime] = useState(false);

    const baseServiceUrl = 'data-upload/files';

    const handleUploadFormInputs = (event: any) => {
        const { name, value } = event.target;

        setUploadForm({
            ...uploadForm,
            [name]: value,
        });
    };

    const handleResetValues = () => {
        if (showDateTime) {
            setShowDateTime(false);
        }

        setUploadForm({
            firstName: '',
            lastName: '',
            clinicName: '',
            comments: '',
        });
        setFile({});
        setDateTime(new Date());
        fileRef.current.value = '';
    };

    const handleFileUpload = async () => {
        setLoading(true);

        const containsFile = !!(typeof file.name === 'string');

        const formData = new FormData();

        if (containsFile) formData.append('fileUpload', file as any);
        formData.append('firstName', uploadForm.firstName);
        formData.append('lastName', uploadForm.lastName);
        formData.append('clinicName', uploadForm.clinicName);
        formData.append('comments', uploadForm.comments);
        formData.append('browserOs', detect()?.os as string);
        formData.append('browserName', detect()?.name as string);

        try {
            const uploadRes = await axios.post(createApiPath(baseServiceUrl), formData);
            const { GUID } = uploadRes?.data?.data?.item;

            if (!containsFile) {
                setLoading(false);
                return setShowModal(true);
            }

            const ibfFileDate = await axios.get(createApiPath(`${baseServiceUrl}/${GUID}/date`));
            const { date } = ibfFileDate?.data?.data?.item;

            if (date) {
                setFileId(GUID);
                setDateTime(new Date(date));
                setLoading(false);
                return setShowDateTime(true);
            }

            setModalText('Oops, did not get a date back');
            setShowModal(true);
            return setLoading(false);
        } catch {
            setModalText('Oops, something went wrong');
            setShowModal(true);
            return setLoading(false);
        }
    };

    const handleFileChange = (event: any) => {
        const { files } = event.target;
        setFile(files[0]);
    };

    const handleButtonDisabled = () => {
        if (
            uploadForm.firstName.length === 0 ||
            uploadForm.lastName.length === 0 ||
            uploadForm.clinicName.length === 0
        ) {
            return 'btn_disabled';
        }

        return '';
    };

    const confirmDate = async () => {
        setLoading(true);

        try {
            const confirmDateResponse = await axios.put(createApiPath(`${baseServiceUrl}/${fileId}/date`), {
                date: dateTime.toISOString(),
            } as any);

            if (confirmDateResponse.statusText === 'OK') {
                await axios.put(createApiPath(`${baseServiceUrl}/${fileId}/confirm`));

                setShowModal(true);
                setShowDateTime(false);
                handleResetValues();
                setLoading(false);
            } else {
                setLoading(false);
                setModalText('Something went wrong confirming, try again');
                setShowModal(true);
            }
        } catch {
            setLoading(false);
            setModalText('Oops, something went wrong validating date');
            setShowModal(true);
            handleResetValues();
        }
    };

    const save = () => {
        if (showDateTime) {
            return confirmDate();
        }
        return handleFileUpload();
    };

    const renderSubtile = () => {
        if (showDateTime) {
            return t('upload.notCurrentDate');
        }
        return t('upload.description');
    };

    const renderModalText = () => {
        if (modalText.length > 0) {
            return t('upload.modalText', { value: modalText });
        }
        return t('upload.success');
    };

    const clearModal = () => {
        handleResetValues();
        setShowModal(false);
        setModalText('');
    };

    return (
        <div className="main-content upload">
            <h1 className="main-content__title">{t('upload.title')}</h1>
            <p className="main-content__description">{renderSubtile()}</p>

            {showDateTime ? (
                <DateTimePicker
                    value={dateTime}
                    openWidgetsOnFocus={false}
                    className="upload__datetime"
                    onChange={setDateTime as any}
                />
            ) : (
                <div className="upload__inputs-container">
                    <div className="upload__input">
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name*"
                            value={uploadForm.firstName}
                            onChange={handleUploadFormInputs}
                        />
                    </div>
                    <div className="upload__input">
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name*"
                            value={uploadForm.lastName}
                            onChange={handleUploadFormInputs}
                        />
                    </div>
                    <div className="upload__input">
                        <input
                            type="text"
                            name="clinicName"
                            placeholder="Clinic*"
                            value={uploadForm.clinicName}
                            onChange={handleUploadFormInputs}
                        />
                    </div>
                    <div className="upload__comments">
                        <textarea
                            name="comments"
                            value={uploadForm.comments}
                            onChange={handleUploadFormInputs}
                            placeholder="Was there any IT security policy issue? If Yes, what was it?"
                        />
                    </div>
                    <div>
                        <input type="file" accept=".ibf" name="fileUpload" ref={fileRef} onChange={handleFileChange} />
                    </div>
                </div>
            )}
            <div className="upload__buttons">
                {loading ? (
                    <Loading type="local" />
                ) : (
                    <>
                        <Button text={t('cancel')} className="btn btn_white" onClick={handleResetValues} />
                        <Button text={t('save')} className={`btn ${handleButtonDisabled()}`} onClick={save} />
                    </>
                )}
            </div>

            {showModal && (
                <Modal>
                    <>
                        <div className="modal__descr-wrapper">
                            {modalText.length > 0 && <div className="modal__iconWarning">!</div>}
                            <div className="modal__descr">{renderModalText()}</div>
                        </div>
                        <div className="modal__buttons">
                            <Button text={t('cancel')} className="btn btn_white" onClick={clearModal} />
                            <Button text={t('ok')} className="btn btn_warning" onClick={clearModal} />
                        </div>
                    </>
                </Modal>
            )}
        </div>
    );
};

export default Upload;
