import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getPendingProvidersData, approveProviders, denyProviders } from 'src/helpers/services/PendingProvidersData';
import { IPendingProvidersTableRows, SidebarAdmin } from 'src/models/table.models';
import { setActivePage } from 'src/stores/appStore/appActions';
import { amplitudeEventsConstants } from 'src/models/consts/amplitude';
import { sendAmplitudeEvent } from 'src/helpers/amplitude';

import Button from '../button/Button';
import PendingProvidersTable from '../pendingProvidersTable/PendingProvidersTable';
import Modal from '../modal/Modal';
import Loading from '../loading/Loading';

interface IPendingProvidersProps {
    isShowErrorModal?: boolean;
    errorMessage?: string;
}

const tableHead = [
    {
        name: 'table.providerName',
        size: 'large',
    },
    {
        name: 'table.npi',
        size: 'small',
    },
    {
        name: 'table.email',
        size: 'large',
    },
];

const PendingProviders = ({ isShowErrorModal, errorMessage }: IPendingProvidersProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [showDenyModal, setShowDenyModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(isShowErrorModal);
    const [selectUser, setSelectUser] = useState([] as string[]);
    const [providersList, setProvidersList] = useState([] as IPendingProvidersTableRows[]);
    const [isUpdateList, setUpdateList] = useState(false);

    useEffect(() => {
        (async () => {
            const providersData = await getPendingProvidersData();
            setProvidersList(providersData.items);
            setLoading(false);
            setShowDenyModal(false);
            setSelectUser([]);
        })();
    }, [isUpdateList]);

    const handleChoosePatient = (patientId: string) => {
        sendAmplitudeEvent(amplitudeEventsConstants.CHOOSE_PENDING_PROVIDER);

        if (selectUser.indexOf(patientId) !== -1) {
            setSelectUser(selectUser.filter((id) => id !== patientId));
        } else {
            setSelectUser([...selectUser, patientId]);
        }
    };

    const handleRedirect = () => {
        sendAmplitudeEvent(amplitudeEventsConstants.REDIRECT_TO_PROVIDERS);
        history.push(SidebarAdmin.providers);
        dispatch(setActivePage(SidebarAdmin.providers));
    };

    const handleApprove = async () => {
        sendAmplitudeEvent(amplitudeEventsConstants.CLICK_APPROVE_PROVIDERS);
        await approveProviders(selectUser);
        setUpdateList(!isUpdateList);
    };

    const handleDenyPatients = async () => {
        sendAmplitudeEvent(amplitudeEventsConstants.CLICK_DENY_PROVIDERS);
        await denyProviders(selectUser);
        setUpdateList(!isUpdateList);
    };

    return (
        <>
            <div className="main-content patientDirectory">
                <h1 className="main-content__title">{t('pendingProviders.title')}</h1>
                <p className="main-content__description">{t('pendingProviders.description')}</p>

                <div className="patientDirectory__search">
                    <div className="patientDirectory__search-right">
                        <div>
                            <Button
                                className={`btn btn_white ${!selectUser.length ? 'btn_disabled' : ''}`}
                                text={t('deny')}
                                dataTestId="denyProvider"
                                onClick={() => setShowDenyModal(true)}
                            />
                        </div>
                        <div>
                            <Button
                                className={`btn ${!selectUser.length ? 'btn_disabled' : ''}`}
                                text={t('approve')}
                                dataTestId="approveProvider"
                                onClick={handleApprove}
                            />
                        </div>
                    </div>
                </div>

                {loading ? (
                    <Loading />
                ) : (
                    <div>
                        <PendingProvidersTable
                            columns={tableHead}
                            rows={providersList}
                            activeRows={selectUser}
                            onChoose={handleChoosePatient}
                            onRedirect={handleRedirect}
                        />
                    </div>
                )}
            </div>
            {showDenyModal && (
                <Modal>
                    <>
                        <div
                            role="button"
                            aria-hidden="true"
                            className="modal__close"
                            data-testid="closeModal"
                            onClick={() => setShowDenyModal(false)}
                        />
                        <div className="modal__iconWarning">!</div>
                        <div className="modal__title" data-testid="denyModalTitle">
                            {selectUser.length > 1
                                ? t('denyModal.titleProviders')
                                : t('denyModal.titleProvider', {
                                      first: `${
                                          providersList.filter((provider) => provider.id === selectUser[0])[0]
                                              ?.firstName
                                      }`,
                                      last: `${
                                          providersList.filter((provider) => provider.id === selectUser[0])[0]?.lastName
                                      }`,
                                  })}
                        </div>
                        {selectUser.length === 1 && (
                            <div className="modal__subtitle">
                                {t('denyModal.subtitle', {
                                    npi: `${providersList.filter((provider) => provider.id === selectUser[0])[0]?.npi}`,
                                })}
                            </div>
                        )}
                        <div className="modal__descr-wrapper">
                            <div className="modal__descr">
                                {selectUser.length > 1 ? t('denyModal.descrProviders') : t('denyModal.descrProvider')}
                            </div>
                        </div>
                        <div className="modal__buttons">
                            <Button
                                className="btn btn_white"
                                text="cancel"
                                onClick={() => setShowDenyModal(false)}
                                dataTestId="cancelModal"
                            />
                            <Button
                                className="btn btn_warning"
                                text="deny"
                                onClick={handleDenyPatients}
                                dataTestId="denyModal"
                            />
                        </div>
                    </>
                </Modal>
            )}
            {showErrorModal && (
                <Modal>
                    <>
                        <div
                            role="button"
                            aria-hidden="true"
                            className="modal__close"
                            data-testid="closeModal"
                            onClick={() => setShowErrorModal(false)}
                        />
                        <div className="modal__iconWarning">!</div>
                        <div className="modal__title" data-testid="errorModalTitle">
                            {t('errorModal.title')}
                        </div>
                        <div className="modal__descr-wrapper">
                            <div className="modal__descr">{t(`${errorMessage}`)}</div>
                        </div>
                        <div className="modal__buttons">
                            <Button
                                className="btn btn_warning"
                                text="ok"
                                onClick={() => setShowErrorModal(false)}
                                dataTestId="closeErrorModal"
                            />
                        </div>
                    </>
                </Modal>
            )}
        </>
    );
};

PendingProviders.defaultProps = {
    isShowErrorModal: false,
    errorMessage: '',
};

export default PendingProviders;
