import React, { ReactChild } from 'react';

interface IModalProps {
    children: ReactChild;
    className?: string;
}

const Modal = ({ children, className }: IModalProps) => {
    return (
        <div className="modal-wrapper">
            <div className={`modal ${className}`}>{children}</div>
        </div>
    );
};

Modal.defaultProps = {
    className: '',
};

export default Modal;
