import { urls } from 'src/models/consts/urls';

import createUrl from './createURL';

interface ISeacrhParam {
    key: string;
    value: string;
}

export default function createPath(mainUrl: string, base: string, searchParams?: ISeacrhParam[]) {
    const newUrl = new URL(base, mainUrl);

    if (searchParams && searchParams.length) {
        searchParams.forEach((p) => {
            newUrl.searchParams.append(p.key, p.value);
        });
    }

    return newUrl.href;
}

export function createApiPath(base: string, searchParams?: ISeacrhParam[]) {
    return createPath(createUrl(urls.API), base, searchParams);
}

export function createPwdPublicPagesPath(base: string, searchParams?: ISeacrhParam[]) {
    return createPath(createUrl(urls.PWD_PUBLIC_PAGES), base, searchParams);
}
