import { AppStateType, AgreementStatus } from 'src/models/app.models';

import { saveOktaData, setActivePage, setRole, setAgreementStatus, setToken } from './appActions';

import ActionTypes from '../actionTypes';

function setDefaultAction() {
    return {
        type: ActionTypes.DEFAULT_TYPE,
        payload: '',
    } as const;
}

const defaultState: AppStateType = {
    activePage: '',
    role: '',
    oktaData: null,
    agreementStatus: AgreementStatus.CONFIRMED,
    token: '',
};

export type AppActionType =
    | ReturnType<typeof setActivePage>
    | ReturnType<typeof setRole>
    | ReturnType<typeof saveOktaData>
    | ReturnType<typeof setAgreementStatus>
    | ReturnType<typeof setDefaultAction>
    | ReturnType<typeof setToken>;

export function appReducer(state = defaultState, action: AppActionType = setDefaultAction()) {
    switch (action?.type) {
        case ActionTypes.SET_ACTIVE_PAGE:
            return {
                ...state,
                activePage: action.payload,
            };
        case ActionTypes.SET_ROLE:
            return {
                ...state,
                role: action.payload,
            };
        case ActionTypes.SAVE_OKTA_DATA:
            return {
                ...state,
                oktaData: action.payload,
            };
        case ActionTypes.SET_AGREEMENT_STATUS:
            return {
                ...state,
                agreementStatus: action.payload,
            };
        case ActionTypes.SET_TOKEN:
            return {
                ...state,
                token: action.payload,
            };
        default:
            return state;
    }
}
