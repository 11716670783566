import amplitude from 'amplitude-js';

export const getAmplitude = (key?: string) => amplitude.getInstance(key);

export const initAmplitude = (appKey: string, callback?: () => void) =>
    getAmplitude().init(
        appKey,
        undefined,
        {
            trackingOptions: {
                ip_address: false,
            },
        },
        callback
    );

export const sendAmplitudeEvent = (eventName: string, properties?: { [k: string]: string }) =>
    getAmplitude().logEvent(eventName, properties);
