import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@material-ui/core';
import clsx from 'clsx';

import Modal from 'src/components/modal/Modal';
import Button from 'src/components/button/Button';
import { acceptUELAAgreement } from 'src/helpers/services/uelaAgreement';
import { RootStateType } from 'src/stores/store';
import { AgreementStatus } from 'src/models/app.models';
import { agreements } from 'src/models/consts/agreements';
import { createPwdPublicPagesPath } from 'src/helpers/createPath';

import { setAgreementStatus } from '../../stores/appStore/appActions';

interface IAgreements {
    children: React.ReactNode | React.ReactNode[];
}

const Agreements = ({ children }: IAgreements) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [agree, setAgree] = useState(false);
    const agreementStatus = useSelector((state: RootStateType) => state.appState.agreementStatus);

    const handleContinue = () => {
        (async () => {
            try {
                await acceptUELAAgreement(agreementStatus);

                dispatch(setAgreementStatus(AgreementStatus.CONFIRMED));

                setAgree(false);
            } catch {
                setAgree(false);
            }
        })();
    };

    const handleAgree = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setAgree(checked);
    };

    if (agreementStatus === AgreementStatus.CONFIRMED) return <>{children}</>;

    return (
        <div className="agreement-wrapper">
            <Modal className="agreement">
                <>
                    <div className="modal__title">{t(agreements.eula?.title)}</div>
                    <div className="modal__descr-wrapper agreement__description">
                        <iframe
                            title={t(agreements.eula?.title)}
                            className="agreement__iframe"
                            src={createPwdPublicPagesPath(agreements.eula?.source)}
                        />
                        <div className="agreement__gradient" />
                    </div>
                    <div className="agreement__action">
                        <div className="agreement__check-box">
                            <Checkbox
                                className="agreement__check-box-action"
                                color="primary"
                                onChange={handleAgree}
                                checked={agree}
                            />
                            <p>{t(agreements.eula?.checkBox)}</p>
                        </div>

                        <Button
                            text={agreements.eula?.button}
                            onClick={handleContinue}
                            className={clsx('btn agreement__action-button', agree || 'btn_disabled')}
                        />
                    </div>
                </>
            </Modal>
        </div>
    );
};

export default Agreements;
