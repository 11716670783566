import React, { useEffect, useMemo } from 'react';
import axios, { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { RootStateType } from 'src/stores/store';
import { isErrorPage } from 'src/helpers/routing';
import { handleError } from 'src/helpers/handleError';
import { ErrorPages } from 'src/models/table.models';

import ErrorPage from '../errorPage/ErrorPage';
import RecallPage from '../recallPage/RecallPage';
import WorkerNotFound from '../workerNotFound/WorkerNotFound';
import PendingProviders from '../pendingProviders/PendingProviders';
import PendingPatients from '../pendingPatients/PendingPatients';

interface IErrorHandler {
    children?: React.ReactNode | React.ReactNode[];
}

const ErrorHandler = ({ children }: IErrorHandler) => {
    const dispatch = useDispatch();
    const activePage = useSelector((state: RootStateType) => state.appState.activePage);

    useEffect(() => {
        axios.interceptors.response.use(undefined, (error: AxiosError) => {
            handleError(dispatch, error);
            return Promise.reject(error);
        });
    }, [dispatch]);

    const content = useMemo(() => {
        switch (activePage) {
            case ErrorPages.authenticationFailure:
                return <ErrorPage type="authenticationFailure" />;
            case ErrorPages.noRole:
                return <ErrorPage type="noRole" />;
            case ErrorPages.PhysicianNotApproved:
                return <ErrorPage type="physicianNotApproved" />;
            case ErrorPages.physicianPending:
                return <ErrorPage type="physicianPending" />;
            case ErrorPages.physicianDenied:
                return <ErrorPage type="physicianDenied" />;
            case ErrorPages.clinicalWorkerNotFound:
                return <WorkerNotFound />;
            case ErrorPages.physiciansClinicAssociationStatusChangeFailed:
                return <PendingProviders isShowErrorModal errorMessage="errorModal.tryAgainLater" />;
            case ErrorPages.physiciansClinicAssociationStatusChangePartiallyFailed:
                return <PendingProviders isShowErrorModal errorMessage="errorModal.partiallyUpdated" />;
            case ErrorPages.patientsClinicAssociationStatusChangeFailed:
                return <PendingPatients isShowErrorModal errorMessage="errorModal.tryAgainLater" />;
            case ErrorPages.patientsClinicAssociationStatusChangePartiallyFailed:
                return <PendingPatients isShowErrorModal errorMessage="errorModal.partiallyUpdated" />;
            case ErrorPages.recall:
                return <RecallPage />;

            default:
                return null;
        }
    }, [activePage]);

    if (isErrorPage(activePage)) return content;

    return <>{children}</>;
};

ErrorHandler.defaultProps = {
    children: undefined,
};

export default ErrorHandler;
