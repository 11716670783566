import axios, { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import { ErrorPages } from 'src/models/table.models';
import { AgreementStatus } from 'src/models/app.models';
import { setActivePage, setAgreementStatus } from 'src/stores/appStore/appActions';

export const handleError = (dispatch: Dispatch<any>, err: Error | AxiosError | unknown) => {
    if (axios.isAxiosError(err)) {
        if (err.message === 'Network Error') dispatch(setActivePage(ErrorPages.recall));
        else if (err.response?.data) {
            switch (err.response.data.error?.code) {
                case 'ClinicalWorkerEulaNotAccepted':
                    dispatch(setAgreementStatus(AgreementStatus.EULA));
                    break;
                case 'PhysicianNotApproved':
                    dispatch(setActivePage(ErrorPages.physicianPending));
                    break;
                case 'ClinicalWorkerNotFound':
                    dispatch(setActivePage(ErrorPages.clinicalWorkerNotFound));
                    break;
                case 'PhysiciansClinicAssociationStatusChangeFailed':
                    dispatch(setActivePage(ErrorPages.physiciansClinicAssociationStatusChangeFailed));
                    break;
                case 'PhysiciansClinicAssociationStatusChangePartiallyFailed':
                    dispatch(setActivePage(ErrorPages.physiciansClinicAssociationStatusChangePartiallyFailed));
                    break;
                case 'PatientsClinicAssociationStatusChangeFailed':
                    dispatch(setActivePage(ErrorPages.patientsClinicAssociationStatusChangeFailed));
                    break;
                case 'PatientsClinicAssociationStatusChangePartiallyFailed':
                    dispatch(setActivePage(ErrorPages.patientsClinicAssociationStatusChangePartiallyFailed));
                    break;

                default:
                    dispatch(setActivePage(ErrorPages.recall));
                    break;
            }
        } else dispatch(setActivePage(ErrorPages.recall));
    }
};
