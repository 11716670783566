import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router';
// import { useOktaAuth } from '@okta/okta-react';

import { setActivePage, setToken } from 'src/stores/appStore/appActions';
import { RootStateType } from 'src/stores/store';
// import { ReactComponent as OmnipodDiscover } from 'src/assets/images/Discover-Logo.svg';
import { sendAmplitudeEvent } from 'src/helpers/amplitude';
import { amplitudeEventsConstants } from 'src/models/consts/amplitude';

interface ISidebarList {
    title: string;
    type: string;
    amplitudeEventName: string;
    disabled?: boolean;
    link?: string;
}

interface ISidebarItem {
    title: string;
    items: ISidebarList[];
}

interface ISidebarProps {
    items: ISidebarItem[];
    isProduction: boolean;
}

const Sidebar = ({ items, isProduction }: ISidebarProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    // const { oktaAuth } = useOktaAuth();

    const activePage = useSelector((state: RootStateType) => state.appState.activePage);

    const changeActivePage = (pageType: string, amplitudeEventName: string, link?: string) => {
        if (link) {
            sendAmplitudeEvent(amplitudeEventName, { link: `${link}` });
            window.open(link);
        } else {
            sendAmplitudeEvent(amplitudeEventName);
            dispatch(setActivePage(pageType));
            history.push(pageType);
        }
    };

    const logout = async () => {
        sendAmplitudeEvent(amplitudeEventsConstants.LOG_OUT);
        history.replace('/');
        dispatch(setToken(''));
        // await oktaAuth.signOut();
    };

    return (
        <div className="sidebar">
            {!isProduction && (
                <div className="sidebar__non-production-label">
                    <div>{t('sidebar.nonProductionUse')}</div>
                </div>
            )}
            <div className="sidebar__logo">{/* <OmnipodDiscover /> */}</div>
            {items.map((item, index) => (
                <ul key={uuidv4()} className="sidebar__list">
                    <div className="sidebar__list-title" data-testid={`sidebarTitle-${index}`}>
                        {t(item.title)}
                    </div>
                    {item.items.map((listItem, i) => (
                        <li
                            key={uuidv4()}
                            role="menuitem"
                            onClick={() => changeActivePage(listItem.type, listItem.amplitudeEventName, listItem?.link)}
                            data-testid={`sidebarItem-${i}`}
                            className={`sidebar__list-item ${listItem?.disabled ? 'disabled' : ''} ${
                                activePage === listItem.type ? 'active' : ''
                            }`}
                            aria-hidden="true"
                        >
                            {t(listItem.title)}
                        </li>
                    ))}
                </ul>
            ))}
            <ul className="sidebar__list last">
                <li
                    className="sidebar__list-item"
                    role="menuitem"
                    aria-hidden="true"
                    onClick={logout}
                    data-testid="sidebarItem-logout"
                >
                    {t('sidebar.signOut')}
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
