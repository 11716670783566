import React from 'react';
import { useTranslation } from 'react-i18next';

import physicianPending from '../../assets/images/webImages/approval-pending.png';

interface ErrorPageProps {
    type?: 'authenticationFailure' | 'noRole' | 'physicianPending' | 'physicianDenied' | 'physicianNotApproved';
}

const ErrorPage = ({ type }: ErrorPageProps) => {
    const { t } = useTranslation();
    if (type === 'physicianPending' || type === 'physicianDenied')
        return (
            <div className="main-content error-message">
                <img src={physicianPending} alt="noPatients" />
                <h2 className="error-message__title">{t(`errorPage.physicianPendingTitle`)}</h2>
                <p className="error-message__description">{t(`errorPage.physicianPendingDescription`)}</p>
            </div>
        );

    return <div>{t(`errorPage.${type}`)}</div>;
};

ErrorPage.defaultProps = {
    type: 'unknownError',
};

export default ErrorPage;
