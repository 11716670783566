import {
    approvePendingProviders,
    denyPendingProviders,
    getPendingProvidersDataConfig,
} from 'src/web-services/Admin.service';

import { createPendingProvidersStructure } from '../createPendingProvidersStructure';

export async function getPendingProvidersData() {
    const res = await getPendingProvidersDataConfig();
    const { items, totalCount } = res?.data || { items: null };

    return {
        items: items?.length ? createPendingProvidersStructure(items) : [],
        totalCount: totalCount || 0,
    };
}

export async function approveProviders(providersId: string[]) {
    await approvePendingProviders(
        providersId.map((providerId) => ({
            id: providerId,
        }))
    );
}

export async function denyProviders(providersId: string[]) {
    await denyPendingProviders(
        providersId.map((providerId) => ({
            id: providerId,
        }))
    );
}
