import React from 'react';

interface LoadingProps {
    className?: string;
    type?: 'full-screen' | 'local' | 'inner-block';
}

const Loading = ({ className, type }: LoadingProps) => {
    return (
        <div className={`spinner ${className} ${type}`}>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

Loading.defaultProps = {
    className: '',
    type: 'inner-block',
};

export default Loading;
