import React from 'react';
import { useTranslation } from 'react-i18next';

import workerNotFoundImage from '../../assets/images/webImages/noProviders.png';

const WorkerNotFound = () => {
    const { t } = useTranslation();

    return (
        <div className="main-content error-message">
            <img src={workerNotFoundImage} alt="workerNotFound" />
            <h2 className="error-message__title">{t('workerNotFound.title')}</h2>
            <p className="error-message__description">{t('workerNotFound.description')}</p>
            <p className="error-message__description">{t('workerNotFound.descriptionSecond')}</p>
        </div>
    );
};

export default WorkerNotFound;
