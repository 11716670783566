import { AgreementStatus } from 'src/models/app.models';

export const agreements = {
    [AgreementStatus.EULA]: {
        source: 'docs/legal.html',
        title: 'agreement.legalTitle',
        checkBox: 'agreement.legalCheckBox',
        button: 'agreement.legalButton',
        AgreementStatus: AgreementStatus.EULA,
    },
};
