export enum variables {
    VERSION = 'REACT_APP_VERSION',
    DEVICE_ID = 'REACT_APP_DEVICE_IDENTIFIER',
    AMPLITUDE_API_KEY = 'REACT_APP_AMPLITUDE_API_KEY',
    INSULET_ID_APP_NAME = 'REACT_APP_INSULET_ID_APP_NAME',
    OKTA_CLIENT_ID = 'REACT_APP_OKTA_CLIENT_ID',
    BUILD_DATE = 'REACT_APP_BUILD_DATE',
    OKTA_ISSUER = 'REACT_APP_OKTA_ISSUER',
    BROWSER_SUPPORT = 'REACT_APP_BROWSER_SUPPORT',
}
