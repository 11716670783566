import { FreeAccessPage, SidebarPage, SidebarAdmin, ErrorPages } from '../models/table.models';
import { UserRole } from '../models/app.models';

export const urlIncludePage = (url: string, pagesEnum: { [s: number]: string }) =>
    Object.values(pagesEnum).includes(url);

export const isFreeAccessPage = (url: string) => urlIncludePage(url, FreeAccessPage);
export const isErrorPage = (url: string) => urlIncludePage(url, ErrorPages);

export const getPostAuthenticationRedirectUrl = (path: string, role: UserRole) => {
    if (isFreeAccessPage(path)) return path;
    if (role === UserRole.Admin) {
        if (!path.includes('admin')) return SidebarAdmin.patients;
    }

    if (role === UserRole.Doctor) {
        if (path.includes('admin')) return SidebarPage.patients;
    }

    return path;
};
