import { amplitudeEventsConstants } from './amplitude';

import { SidebarAdmin, SidebarPage, FreeAccessPage } from '../table.models';

export const adminsSidebar = [
    {
        title: 'sidebar.directory',
        items: [
            {
                title: 'sidebar.patients',
                amplitudeEventName: amplitudeEventsConstants.VIEW_PATIENTS,
                type: SidebarAdmin.patients,
            },
            {
                title: 'sidebar.providers',
                amplitudeEventName: amplitudeEventsConstants.VIEW_PROVIDERS,
                type: SidebarAdmin.providers,
            },
        ],
    },
    {
        title: 'sidebar.pendingRequests',
        items: [
            {
                title: 'sidebar.patients',
                amplitudeEventName: amplitudeEventsConstants.VIEW_PENDING_PATIENTS,
                type: SidebarAdmin.pendingPatients,
            },
            {
                title: 'sidebar.providers',
                amplitudeEventName: amplitudeEventsConstants.VIEW_PENDING_PROVIDERS,
                type: SidebarAdmin.pendingProviders,
            },
        ],
    },
    {
        title: 'sidebar.resources',
        items: [
            {
                title: 'sidebar.help',
                amplitudeEventName: amplitudeEventsConstants.VIEW_HELP,
                type: FreeAccessPage.help,
            },
            {
                title: 'sidebar.about',
                amplitudeEventName: amplitudeEventsConstants.VIEW_ABOUT,
                type: FreeAccessPage.about,
            },
            {
                title: 'sidebar.upload',
                amplitudeEventName: amplitudeEventsConstants.VIEW_UPLOAD,
                type: FreeAccessPage.upload,
            },
        ],
    },
    {
        title: 'sidebar.omnipodConnect',
        items: [
            {
                title: 'sidebar.home',
                type: FreeAccessPage.home,
                amplitudeEventName: amplitudeEventsConstants.VIEW_HOME,
                link: 'https://www.omnipod.com',
            },
            {
                title: 'sidebar.training',
                type: FreeAccessPage.training,
                amplitudeEventName: amplitudeEventsConstants.VIEW_TRAINING,
                link: 'https://www.omnipod.com/hcp/clinical-resources/provider-resources',
            },
            // {
            //     title: 'sidebar.prescribe',
            //     type: FreeAccessPage.prescribe,
            //     link: 'https://www.omnipod.com/hcp/reimbursement/how-to-prescribe-omnipod-dash',
            // },
        ],
    },
];

export const doctorsSidebar = [
    {
        title: 'sidebar.directory',
        items: [
            {
                title: 'sidebar.patients',
                amplitudeEventName: amplitudeEventsConstants.VIEW_PATIENTS,
                type: SidebarPage.patients,
            },
        ],
    },
    {
        title: 'sidebar.resources',
        items: [
            {
                title: 'sidebar.help',
                amplitudeEventName: amplitudeEventsConstants.VIEW_HELP,
                type: FreeAccessPage.help,
            },
            {
                title: 'sidebar.about',
                amplitudeEventName: amplitudeEventsConstants.VIEW_ABOUT,
                type: FreeAccessPage.about,
            },
            {
                title: 'sidebar.upload',
                amplitudeEventName: amplitudeEventsConstants.VIEW_UPLOAD,
                type: FreeAccessPage.upload,
            },
        ],
    },
    {
        title: 'sidebar.omnipodConnect',
        items: [
            {
                title: 'sidebar.home',
                type: FreeAccessPage.home,
                amplitudeEventName: amplitudeEventsConstants.VIEW_HOME,
                link: 'https://www.omnipod.com',
            },
            {
                title: 'sidebar.training',
                type: FreeAccessPage.training,
                amplitudeEventName: amplitudeEventsConstants.VIEW_TRAINING,
                link: 'https://www.omnipod.com/hcp/clinical-resources/provider-resources',
            },
            // {
            //     title: 'sidebar.prescribe',
            //     type: FreeAccessPage.prescribe,
            //     link: 'https://www.omnipod.com/hcp/reimbursement/how-to-prescribe-omnipod-dash',
            // },
        ],
    },
];

export const pendingSidebar = [
    {
        title: 'sidebar.omnipodConnect',
        items: [
            {
                title: 'sidebar.home',
                type: FreeAccessPage.home,
                amplitudeEventName: amplitudeEventsConstants.VIEW_HOME,
                link: 'https://www.omnipod.com',
            },
            {
                title: 'sidebar.training',
                type: FreeAccessPage.training,
                amplitudeEventName: amplitudeEventsConstants.VIEW_TRAINING,
                link: 'https://www.omnipod.com/hcp/clinical-resources/provider-resources',
            },
            // {
            //     title: 'sidebar.prescribe',
            //     type: FreeAccessPage.prescribe,
            //     link: 'https://www.omnipod.com/hcp/reimbursement/how-to-prescribe-omnipod-dash',
            // },
        ],
    },
];

export const webUploaderSidebar = [
    {
        title: 'sidebar.uploadTitle',
        items: [
            {
                title: 'sidebar.upload',
                amplitudeEventName: amplitudeEventsConstants.VIEW_UPLOAD,
                type: FreeAccessPage.upload,
            },
        ],
    },
];
