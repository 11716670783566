import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import axios from 'axios';

import { createApiPath } from 'src/helpers/createPath';
import { setActivePage, setToken } from 'src/stores/appStore/appActions';
// components
import Button from 'src/components/button/Button';
import Loading from 'src/components/loading/Loading';
import Modal from 'src/components/modal/Modal';

const Login = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalText, setModalText] = useState('');
    const [showModal, setShowModal] = useState(false);

    const clear = () => {
        setPassword('');
    };

    const login = async () => {
        setLoading(true);

        await axios
            .post(createApiPath('data-upload/login'), { uploaderKey: password })
            .then((res) => {
                if (res.data?.success) {
                    dispatch(setToken(res.data.token));
                    setLoading(false);
                    dispatch(setActivePage('/upload'));
                    history.replace('/upload');
                }
            })
            .catch((error) => {
                const { data } = error?.response;

                if (data?.message) {
                    setLoading(false);
                    setModalText(data?.message);
                    setShowModal(true);
                }
            });
    };

    const disableSaveButton = () => {
        if (password.length === 0) {
            return 'btn_disabled';
        }
        return '';
    };

    const clearModal = () => {
        clear();
        setModalText('');
        setShowModal(false);
    };

    const handleKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            login();
        }
    };

    return (
        <div className="login">
            <div className="login__container">
                <h1 className="main-content__title login__title">{t('login')}</h1>
                <div className="login__input-container">
                    <input
                        type="password"
                        name="password"
                        value={password}
                        onKeyUp={handleKeyUp}
                        onChange={(event) => setPassword(event.target.value)}
                        placeholder="Password"
                    />
                    <>
                        {loading ? (
                            <div className="login__loading">
                                <Loading type="local" />
                            </div>
                        ) : (
                            <div className="login__buttons">
                                <Button text={t('cancel')} className="btn btn_white" onClick={clear} />
                                <Button text={t('login')} className={`btn ${disableSaveButton()}`} onClick={login} />
                            </div>
                        )}
                    </>
                </div>
            </div>
            {showModal && (
                <Modal>
                    <>
                        <div className="modal__descr-wrapper">
                            {modalText.length > 0 && <div className="modal__iconWarning">!</div>}
                            <div className="modal__descr">{modalText}</div>
                        </div>
                        <div className="modal__buttons">
                            <Button text={t('ok')} className="btn btn_warning" onClick={clearModal} />
                        </div>
                    </>
                </Modal>
            )}
        </div>
    );
};

export default Login;
