import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory, Switch } from 'react-router-dom';
// import { Security, // LoginCallback } from '@okta/okta-react';
// import { OktaAuth, // toRelativeUrl } from '@okta/okta-auth-js';

// import LoginInsuletIDCallback from 'src/components/loginInsuletIDCallback/LoginInsuletIDCallback';
import {
    initAmplitude,
    // sendAmplitudeEvent
} from 'src/helpers/amplitude';
// import { amplitudeEventsConstants } from 'src/models/consts/amplitude';
// import Landing from 'src/components/landing/Landing';
// import createUrl from 'src/helpers/createURL';
// import { urls } from 'src/models/consts/urls';
import { getRuntimeConfig } from 'src/helpers/envReader';
import { variables } from 'src/models/consts/variables';
import Login from 'src/pages/Login';
// import { FreeAccessPage } from 'src/models/table.models';
import { RootStateType } from 'src/stores/store';
import Home from 'src/pages/Home';

// import PatientDetails from '../pages/PatientDetails';

import '../styles/common.scss';

// const oktaAuth = new OktaAuth({
//     issuer: getRuntimeConfig(variables.OKTA_ISSUER),
//     clientId: getRuntimeConfig(variables.OKTA_CLIENT_ID),
//     redirectUri: `${window.location.origin}/login/callback`,
//     tokenManager: {
//         autoRenew: false,
//     },
// });

const App = () => {
    const history = useHistory();

    const token = useSelector((state: RootStateType) => state.appState.token);

    useEffect(() => {
        if (!(token.length > 0)) {
            history.replace('/');
        }
    }, [token, history]);

    useEffect(() => {
        const amplitudeKey = getRuntimeConfig(variables.AMPLITUDE_API_KEY);
        const { href } = window.location;
        const reportInPath = href.includes('report');
        if (amplitudeKey && !reportInPath) {
            initAmplitude(amplitudeKey);
        }
    }, []);

    // const returnUrl = encodeURIComponent(`${window.location.origin}/insuletid/callback`);
    // const INSULET_ID_APP_NAME = getRuntimeConfig(variables.INSULET_ID_APP_NAME);
    // const loginURL = `${createUrl(urls.INSULET_ID)}/login?app=${INSULET_ID_APP_NAME}&ReturnURL=${returnUrl}`;

    // const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    //     history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    // };

    if (token.length > 0) {
        return (
            <Switch>
                <Route exact path="/upload" component={Home} />
            </Switch>
        );
    }

    return (
        // <Security
        //     oktaAuth={oktaAuth}
        //     restoreOriginalUri={restoreOriginalUri}
        //     onAuthRequired={() => {
        //         sendAmplitudeEvent(amplitudeEventsConstants.REDIRECT_TO_LOGIN, { redirectPage: loginURL });
        //     }}
        // >
        <Switch>
            <Route exact path="/" component={Login} />
            {/* <Route exact path="/login/callback" component={LoginCallback} />
            <Route exact path="/insuletid/callback" component={LoginInsuletIDCallback} />
            <Route exact path="/report" component={PatientDetails} />
            <Route path="/" component={Landing} /> */}
        </Switch>
        // </Security>
    );
};

export default App;
