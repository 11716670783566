import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { BrowserRouter } from 'react-router-dom';

import './locales/i18n';

import './styles/common.scss';

import rootReducer from './stores/store';
import App from './app/App';

ReactDOM.render(
    <Provider store={createStore(rootReducer)}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('react-root')
);
