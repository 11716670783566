import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { parseZone, locale } from 'moment';

import en from './en/index.json';

const resources = {
    en: {
        translation: en,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    interpolation: {
        escapeValue: false,
        format: (value, format, lang) => {
            locale(lang);
            if (format?.includes('date')) {
                if (format === 'date-usual') return parseZone(value).format('MMM D, YYYY');
                if (format === 'date-fullMonth') return parseZone(value).format('MMMM D, YYYY');
                if (format === 'date-dayName') return parseZone(value).format('dddd');
            }

            return value;
        },
    },
});

export default i18n;
