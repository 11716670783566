export const amplitudeEventsConstants = {
    SET_USER_TYPE: 'set user type',
    REDIRECT_TO_LOGIN: 'redirect to login',
    SUCCESSFUL_LOGIN: 'successful login',
    FAILED_LOGIN: 'failed login',
    LOG_OUT: 'log out',
    REDIRECT_TO_PROVIDERS: 'redirect to providers',
    VIEW_LEGAL_INFORMATION: 'view legal information',
    VIEW_ABOUT_INSULET: 'view about insulet',
    VIEW_CONTACT_US: 'view contact us',
    VIEW_PRIVACY_POLICY: 'view privacy policy',
    VIEW_CALIFORNIA_SUPPLY_CHAIN_ACT: 'view california supply chain act',
    VIEW_TERMS_OF_USE: 'view terms of use',
    VIEW_END_USER_LICENSE_AGREEMENT: 'view end user license agreement',
    VIEW_IMPORTANT_SAFETY_INFORMATION: 'view important safety information',
    VIEW_HIPAA_PRIVACY_NOTICE: 'view hipaa privacy notice',
    VIEW_INSULET_ALERTS: 'view insulet alerts',
    VIEW_SECURITY_AT_INSULET: 'view security at insulet',
    VIEW_FACEBOOK: 'view facebook',
    VIEW_TWITTER: 'view twitter',
    VIEW_INSTAGRAM: 'view instagram',
    VIEW_YOUTUBE: 'view youtube',
    VIEW_IFU_PORTAL_INSTRUCTIONS: 'view ifu portal instructions',
    VIEW_IFU_PATIENT_APP_INSTRUCTIONS: 'view ifu patient app instructions',
    CLICK_SUPPORT_EMAIL: 'click support email',
    CLICK_VIEW_REPORT: 'click view report',
    CLICK_PRINT_REPORT: 'click print report',
    CLICK_APPROVE_PROVIDERS: 'click approve providers',
    CLICK_DENY_PROVIDERS: 'click deny providers',
    CHOOSE_PENDING_PROVIDER: 'choose pending provider',
    CHOOSE_APPROVED_PATIENT: 'choose approved patient',
    SELECT_ACTIVE_DAYS: 'select active days',
    CHOOSE_PENDING_PATIENTS: 'choose pending patients',
    REDIRECT_TO_PATIENTS: 'redirect to patients',
    CLICK_APPROVE_PATIENTS: 'click approve patients',
    CLICK_DENY_PATIENTS: 'click deny patients',
    VIEW_PATIENTS: 'view patients',
    VIEW_PROVIDERS: 'view providers',
    VIEW_PENDING_PATIENTS: 'view pending patients',
    VIEW_PENDING_PROVIDERS: 'view pending providers',
    VIEW_HELP: 'view help',
    VIEW_ABOUT: 'view about',
    VIEW_HOME: 'view home',
    VIEW_TRAINING: 'view training',
    VIEW_UPLOAD: 'view dash/eros data upload',
};
