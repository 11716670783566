import { IPendingProvidersTableRows } from 'src/models/table.models';

interface IPendingProviders {
    id: string;
    name: { first: string; last: string };
    nationalProviderIdentifier: string;
    email: string;
}

export function createPendingProvidersStructure(items: IPendingProviders[]) {
    const newItems: IPendingProvidersTableRows[] = [];
    items.forEach((item) => {
        const patientData = {
            id: item.id,
            firstName: item.name?.first ? item.name?.first : '',
            lastName: item.name?.last ? item.name?.last : '',
            npi: item?.nationalProviderIdentifier ? String(item?.nationalProviderIdentifier) : '',
            email: item?.email ? item?.email : '',
        };
        newItems.push(patientData);
    });
    return newItems;
}
