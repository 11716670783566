import React from 'react';
import { useTranslation } from 'react-i18next';

interface IButtonProps {
    text: string;
    className?: string;
    onClick: () => void;
    dataTestId?: string;
}

const Button = ({ text, className, dataTestId, onClick }: IButtonProps) => {
    const { t } = useTranslation();
    return (
        <button className={className} data-testid={dataTestId} type="button" onClick={onClick}>
            {t(text)}
        </button>
    );
};

Button.defaultProps = {
    className: 'btn',
    dataTestId: '',
};

export default Button;
