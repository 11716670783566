import moment from 'moment';

import { IPendingPatientsTableRows } from 'src/models/table.models';

interface IPendingPatients {
    birthdate: string;
    id: string;
    name: { first: string; last: string };
    requestedDate: string;
    mrn: string;
}

export function createPendingPatientsStructure(items: IPendingPatients[]) {
    const newItems: IPendingPatientsTableRows[] = [];
    items.forEach((item) => {
        const patientData = {
            id: item.id,
            firstName: item.name?.first ? item.name?.first : '',
            lastName: item.name?.last ? item.name?.last : '',
            mrn: item?.mrn ? String(item?.mrn) : ' ',
            dob: item?.birthdate ? moment(item?.birthdate).format('MM/DD/YYYY') : '--',
            requestedDate: item?.requestedDate ? moment(item?.requestedDate).format('MM/DD/YYYY') : '--',
        };
        newItems.push(patientData);
    });
    return newItems;
}
