import { combineReducers } from 'redux';

import { appReducer } from './appStore/appReducers';

const rootReducer = combineReducers({
    appState: appReducer,
});

export default rootReducer;

export type RootStateType = ReturnType<typeof rootReducer>;
