import { IOktaData } from 'src/helpers/parseOktaData';
import { AgreementStatus } from 'src/models/app.models';

import ActionTypes from '../actionTypes';

export function setActivePage(page: string) {
    return {
        type: ActionTypes.SET_ACTIVE_PAGE,
        payload: page,
    } as const;
}

export function setRole(role: string) {
    return {
        type: ActionTypes.SET_ROLE,
        payload: role,
    } as const;
}

export function saveOktaData(oktaData: IOktaData) {
    return {
        type: ActionTypes.SAVE_OKTA_DATA,
        payload: oktaData,
    } as const;
}

export function setAgreementStatus(status: AgreementStatus) {
    return {
        type: ActionTypes.SET_AGREEMENT_STATUS,
        payload: status,
    } as const;
}

export function setToken(token: string) {
    return {
        type: ActionTypes.SET_TOKEN,
        payload: token,
    } as const;
}
