import React from 'react';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { IPendingProvidersTableRows, ITableColumns } from 'src/models/table.models';

import CheckBox from '../checkBox/CheckBox';
import noProvidersImage from '../../assets/images/webImages/noPatients.png';
import Button from '../button/Button';

interface IPendingProvidersTableProps {
    columns: ITableColumns[];
    rows: IPendingProvidersTableRows[];
    activeRows?: string[];
    onChoose: (id: string) => void;
    onRedirect: () => void;
}

const PendingProvidersTable = ({ columns, rows, activeRows, onChoose, onRedirect }: IPendingProvidersTableProps) => {
    const { t } = useTranslation();
    const handleChoose = (patientId: string) => {
        onChoose(patientId);
    };

    const showEmptyContent = () => {
        return (
            <div className="tableEmptyContent">
                <img src={noProvidersImage} alt="noProviders" />
                <h2 className="tableEmptyContent__title">{t('pendingProviders.emptyTitle')}</h2>
                <p className="tableEmptyContent__descr">{t('pendingProviders.emptyDescr')}</p>
                <Button
                    text={t('pendingProviders.redirectPendingProviders')}
                    onClick={() => onRedirect()}
                    dataTestId="redirectApprovedProviders"
                />
            </div>
        );
    };

    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        {columns.map((item) => (
                            <td key={uuidv4()} className={`${item?.size ? item.size : ''}`}>
                                {item.sort ? <UnfoldMoreIcon /> : null} {t(item.name)}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows &&
                        rows.map((row, i) => (
                            <tr key={uuidv4()} className={activeRows?.indexOf(row.id) !== -1 ? 'active' : ''}>
                                <td className="large">
                                    <CheckBox
                                        activeId={activeRows?.filter((id) => id === row.id)[0]}
                                        id={row.id}
                                        dataTestId={`checkbox-${i}`}
                                        onChange={handleChoose}
                                    />
                                    {row.firstName && row.lastName
                                        ? t('patientDirectory.nameFull', {
                                              first: `${row.firstName}`,
                                              last: `${row.lastName}`,
                                          })
                                        : t('patientDirectory.name', {
                                              first: `${row.firstName}`,
                                              last: `${row.lastName}`,
                                          })}

                                    {!row.firstName && !row.lastName && '--'}
                                </td>
                                <td className="small">{row.npi}</td>
                                <td className="large">{row.email}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {!rows.length && showEmptyContent()}
        </div>
    );
};

PendingProvidersTable.defaultProps = {
    activeRows: [],
};

export default PendingProvidersTable;
